import React, { PureComponent } from "react";
import PropTypes from "prop-types";

export default class Container extends PureComponent {
  static propTypes = {
    hidden: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
  };

  static defaultProps = {
    hidden: false,
    children: null,
  };

  render() {
    const { hidden, children } = this.props;
    return <div style={{ display: hidden ? "none" : "block" }}>{children}</div>;
  }
}
