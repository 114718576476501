import React, { PureComponent } from "react";
import { connect, ConnectedProps } from "react-redux";
import ReactResizeDetector from "react-resize-detector";
import { BrowserRouter as Router } from "react-router-dom";

import Loader from "./components/Loader";
import DesktopForm from "./forms/Desktop";
import ErrorDialog from "./forms/ErrorDialog";

import {
  ENUMERATION_APPLIANCE,
  ENUMERATION_BANK,
  ENUMERATION_CIRCUIT_BREAKER,
  ENUMERATION_COMMODITY,
  ENUMERATION_ESTIMATE,
  ENUMERATION_INSTALLATION_FLOOR,
  ENUMERATION_PARTNER,
  ENUMERATION_POSITIONS,
  ENUMERATION_SUPPLIER,
  ENUMERATION_TARIFF,
  ENUMERATION_TITLE_PREFIX,
  ENUMERATION_TITLE_SUFFIX,
  ENUMERATION_PAYMENT_METHOD,
  ENUMERATION_SETTINGS,
} from "./constants/enumerations";
import { RESOLUTION_DESKTOP, RESOLUTION_MOBILE, RESOLUTION_THRESHOLD } from "./constants";
import {
  CONTRACT_CONFIRM_FAILURE,
  CONTRACT_PRODUCTS_FAILURE,
  CONTRACT_SAVE_FAILURE,
  ENUMERATION_FAILURE,
  CONTRACT_CREATE_PDF_FAILURE,
} from "./constants/actions";

import { isEnumerationsReady } from "./reducers/enumerations";
import { confirmForm, fetchForm, fetchProducts, saveForm, createForm, createPdf } from "./actions/contract";
import { fetchEnumeration } from "./actions/enumerations";
import { getData } from "./actions/user";
import locs from "./localization";
import { getInitialValues, isInitialValuesReady, getSupplyPointData, isTransferNewProcess } from "./selectors/contract";
import { getProcessFromUser, isUserReady } from "./selectors/user";

const requiredEnumerations = [
  // Required for calculation
  ENUMERATION_APPLIANCE,
  ENUMERATION_CIRCUIT_BREAKER,
  ENUMERATION_COMMODITY,
  ENUMERATION_ESTIMATE,
  ENUMERATION_PARTNER,
  ENUMERATION_TARIFF,

  // Required for contact
  ENUMERATION_POSITIONS,
  ENUMERATION_TITLE_PREFIX,
  ENUMERATION_TITLE_SUFFIX,

  // Required for addresses
  ENUMERATION_INSTALLATION_FLOOR,

  // Required for payment
  ENUMERATION_BANK,
  ENUMERATION_PAYMENT_METHOD,

  // Required for supply point
  ENUMERATION_SUPPLIER,

  // Settings
  ENUMERATION_SETTINGS,
];

interface AppProps {}

interface AppState {
  resolution?: typeof RESOLUTION_DESKTOP | typeof RESOLUTION_MOBILE;
  fetchFormCalled: boolean;
}

function mapStateToProps(state) {
  const { enumerations, error } = state;

  return {
    /** get redux values */
    initialValues: getInitialValues(state),
    isReady: isEnumerationsReady(enumerations, requiredEnumerations) && isInitialValuesReady(state) && isUserReady(state),
    processFromUser: getProcessFromUser(state),
    formValues: getSupplyPointData(state),
    isTransferNewProcess: isTransferNewProcess(state), // Is in the "?process=prepis" part of a portal?
    error,
  };
}

const mapDispatchToProps = {
  fetchEnumeration,
  fetchForm,
  fetchProducts,
  saveForm,
  confirmForm,
  getData,
  createForm,
  createPdf,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

class App extends PureComponent<AppProps & PropsFromRedux, AppState> {
  static defaultProps = {
    initialValues: {},
    error: {
      error: null,
      count: 0,
      query: null,
    },
  };

  state = {
    resolution: null,
    fetchFormCalled: false,
  };

  componentDidMount() {
    requiredEnumerations.forEach(this.props.fetchEnumeration);
    this.props.getData(true);

    // Recovery form state ONLY in development mode!
    if (process.env.NODE_ENV === "development") {
      this.props.fetchForm();
    }
  }

  // TODO: Try to fix this (suitable for refactor)
  static getDerivedStateFromProps(props: Partial<AppProps & PropsFromRedux>, state: AppState) {
    if (props.processFromUser === "prolongation" && !state.fetchFormCalled) {
      props.fetchForm();

      return {
        fetchFormCalled: true,
      };
    }

    return null;
  }

  handleResize = (width: number) => {
    const resolution = width >= RESOLUTION_THRESHOLD ? RESOLUTION_DESKTOP : RESOLUTION_MOBILE;
    if (this.state.resolution === null) {
      this.setState({ resolution });
    }
  };

  renderDesktopForm() {
    const { initialValues } = this.props;
    return (
      <DesktopForm
        initialValues={initialValues}
        onDataChange={this.props.saveForm}
        fetchProducts={this.props.fetchProducts}
        onSubmit={this.props.confirmForm}
        onGetData={this.props.getData}
        createForm={this.props.createForm}
      />
    );
  }

  renderError() {
    const { error } = this.props;
    if (error.count === 0) return null;

    let message = error.count > 2 ? locs("titles.errorMultiple") : locs("titles.error");
    let action;

    if (error.status === 404 || error.status === 401) {
      // Redirect to the main page if the session has expired or the contract was not found

      // The "main" page is different for the "transfer" process
      const url = this.props.isTransferNewProcess ? "/?process=prepis" : "/";
      action = () => window.location.replace(url);
      message = locs("titles.session_expired");
    } else {
      switch (error.error) {
        case ENUMERATION_FAILURE:
          action = () => window.location.reload();
          break;
        case CONTRACT_SAVE_FAILURE:
          action = () => this.props.saveForm(error.query);
          break;
        case CONTRACT_CONFIRM_FAILURE:
          action = this.props.confirmForm;
          break;
        case CONTRACT_PRODUCTS_FAILURE:
          action = this.props.fetchProducts;
          break;
        case CONTRACT_CREATE_PDF_FAILURE:
          action = this.props.createPdf;
          break;
      }
    }

    return <ErrorDialog message={message} onClick={action} />;
  }

  render() {
    const { isReady } = this.props;

    return (
      <div>
        <ReactResizeDetector handleWidth onResize={this.handleResize} />
        <Loader loaded={isReady}>
          <Router>{this.renderDesktopForm()}</Router>
        </Loader>
        {this.renderError()}
      </div>
    );
  }
}

export default connector(App);
