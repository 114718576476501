import React from "react";
import { Field, InjectedFormProps, reduxForm } from "redux-form";
import { connect, ConnectedProps } from "react-redux";
import locs from "../../../localization";

import Container from "../../../containers/Container";
import Column from "../../../containers/Column";
import Title from "../../../components/Title";
import Button from "../../../components/Button";
import styles from "../styles.scss";

import { isElectricity, getTransfer, getDistributionZone, getLifecycle } from "../../../selectors/contract";

import { compose } from "redux";
import TransferDocuments from "../../../composites/TransferDocuments";
import InlineLoader from "../../../components/Loader/InlineLoader";
import Link from "../../../components/Link";

const formKey = "calculator";

function mapStateToProps(state) {
  return {
    isElectricity: isElectricity(state),
    transfer: getTransfer(state),
    distributionZone: getDistributionZone(state),
    lifecycle: getLifecycle(state),
  };
}

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface DocumentFormProps {
  previousPage: () => void;
}

function DocumentForm(props: PropsFromRedux & InjectedFormProps & DocumentFormProps) {
  const { handleSubmit, isElectricity, distributionZone, lifecycle, submitting, previousPage } = props;

  return (
    <Container>
      <div className={styles.transferFiles}>
        <Column>
          <Title name={locs("titles.transfer")} tag="h1" big={true} />
        </Column>
        <Column>
          <Title name={locs("titles.uploadRequiredAttachments")} underline />
        </Column>
        <Field
          name="transfer.documents"
          component={TransferDocuments}
          props={{
            isElectricity,
            distributionZone,
            lifecycle,
          }}
        />
        <div className={styles.footer}>
          <Column width={2} weights={["1", 2]}>
            <Link className={styles.transferIncorrect} label={locs("actions.go_back")} onClick={previousPage} />
            <Button disabled={submitting} onClick={handleSubmit} className={styles.transferSubmit}>
              {submitting ? <InlineLoader height={12} /> : locs("actions.nextTransfer")}
            </Button>
          </Column>
        </div>
      </div>
    </Container>
  );
}

export default compose(
  reduxForm({
    form: formKey, // <------ same form name
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  }),
  connector
)(DocumentForm) as React.ComponentClass<{}, {}>;
