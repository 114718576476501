import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { InjectedFormProps, reduxForm, Field } from "redux-form";
import locs from "../../localization";
import Container from "../../containers/Container";
import Column from "../../containers/Column";
import FormGroup from "../../components/UI/FormGroup";
import Title from "../../components/Title";
import Button from "../../components/Button";

import styles from "../Calculator/styles.scss";

import { gasConsumptionChange } from "../Calculator/Consumption/Gas/Consumption";
import SelectCommodity from "../Calculator/SelectCommodity";
import Calculate, { calculateFormChange } from "../Calculator/Consumption/Calculate";
import PartnerType from "../Calculator/PartnerType";
import MapComponent from "../Calculator/Map";
import User from "../User";

import { seleniumClass } from "../../helpers";

import formValidation from "../../validations/formValidation";
import isNotEmpty from "../../validations/isNotEmpty";
import InlineLoader from "../../components/Loader/InlineLoader";
import { getEnumeration, getEstimates, getTariffs } from "../../selectors/enumerations";
import { getCommodity, isElectricity, isGas, isHousehold, isPartnerIdSelected } from "../../selectors/contract";
import { compose } from "redux";
import { CalculatorValues } from "../../reducers/types";
import { updateTariffAndCircuitBreakers } from "../Calculator/Consumption/Electricity/Consumption";
import { ENUMERATION_CIRCUIT_BREAKER } from "../../constants/enumerations";
import { CircuitBreaker } from "../../constants/interfaces";

const formKey = "calculator";

function mapStateToProps(state) {
  return {
    commodity: getCommodity(state),
    electricity: isElectricity(state),
    gas: isGas(state),
    household: isHousehold(state),
    estimates: getEstimates(state),
    isPartnerIdSelected: isPartnerIdSelected(state),
    tariffs: getTariffs(state),
    circuitBreakers: getEnumeration<CircuitBreaker>(state, ENUMERATION_CIRCUIT_BREAKER),
  };
}

interface CalculatorProps {
  signed: boolean;
}

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

function Calculator(props: CalculatorProps & PropsFromRedux & InjectedFormProps) {
  const { commodity, electricity, gas, household, signed, change, untouch, submitting, estimates, isPartnerIdSelected } = props;

  let title = "titles.calculator_title";
  let mapTitle = "";

  if (gas) {
    mapTitle = "titles.your_location_gas";
  } else if (electricity) {
    mapTitle = "titles.your_location_electricity";
  }

  return (
    <Container>
      <div>
        <SelectCommodity name="commodityValue" />
        <Column>
          <Title name={title} className={styles.main_title} />
        </Column>
        {signed && <User />}
        <Column>
          <div className={styles.consumption_container}>
            <Title name="titles.your_year_consumption" tag="h3" />
            <FormGroup>
              <PartnerType name="partnerValue" disabled={signed || isPartnerIdSelected} />
            </FormGroup>
            <FormGroup>
              <Field
                name={`calculate.${commodity.value}`}
                component={Calculate}
                props={{
                  untouch,
                  change,
                  commodity,
                  estimates,
                }}
              />
            </FormGroup>
          </div>
          <div className={styles.map_container}>
            <Title name={mapTitle} tag="h3" className={styles.map_title} />
            <Field
              name="regionRuianCode"
              type="select"
              component={MapComponent}
              props={{ untouch }}
              validate={[formValidation(isNotEmpty, locs("errors.regionRequired"))]}
            />
          </div>
        </Column>
        <div className={styles.calculateButtonContainer}>
          <Button className={seleniumClass("next")} disabled={submitting} onClick={props.handleSubmit}>
            {submitting ? <InlineLoader height={12} /> : locs("actions.calculate_savings")}
          </Button>
        </div>
      </div>
    </Container>
  );
}

export default compose(
  connector,
  reduxForm({
    form: formKey, // <------ same form name
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    onChange: (
      values: CalculatorValues,
      dispatch,
      props: InjectedFormProps & PropsFromRedux,
      previousValue: CalculatorValues
    ) => {
      // Estimates and toggling combinations
      calculateFormChange(values, props.estimates, props.commodity.value, dispatch, props, previousValue);

      if (props.gas) {
        // Gas callbacks
        gasConsumptionChange({
          values: values.calculate?.P2?.detail,
          previousValues: previousValue.calculate?.P2?.detail,
          dispatch,
          props,
          path: "calculate.P2.detail",
        });
      }

      // Update tariffs and circuit breakers
      if (!props.gas && values.calculate?.E1?.knownConsumption) {
        updateTariffAndCircuitBreakers({
          values: values.calculate?.E1?.detail,
          previousValues: previousValue?.calculate?.E1?.detail,
          dispatch,
          props,
          tariffs: props.tariffs,
          circuitBreakers: props.circuitBreakers,
          path: "calculate.E1.detail",
        });
      }
    },
  })
)(Calculator);
