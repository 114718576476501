import React from "react";
import { connect, ConnectedProps } from "react-redux";
import locs from "../../localization";

import Column from "../../containers/Column";
import Address from "../../composites/Address";
import Checkbox from "../../components/Checkbox";
import Title from "../../components/Title";
import Input from "../../components/Input";
import Select from "../../components/Select";
import TooltipContainer from "../../containers/TooltipContainer";

import GasConsumption from "../Calculator/Consumption/Gas/Consumption";
import ElectricityConsumption from "../Calculator/Consumption/Electricity/Consumption";

import formValidation from "../../validations/formValidation";
import isEIC from "../../validations/isEIC";
import isEAN from "../../validations/isEAN";
import isLength from "../../validations/isLength";
import eicFormatter from "../../formatters/eic";
import eanFormatter from "../../formatters/ean";

import styles from "./styles.scss";
import theme from "./theme.scss";

import { UNKNOWN, EIC_PREFIX, EAN_PREFIX, TYPE_NEW_OM } from "../../constants";
import { generateMonths, formatEnumerationData } from "../../helpers";

import CurrentSupplier from "./CurrentSupplier";
import GasUsage from "./GasUsage";
import { Field } from "redux-form";
import { Appliance, InstallationFloor, Supplier } from "../../constants/interfaces";
import { getEnumeration } from "../../selectors/enumerations";
import { getLifecycle, getPartnerValue, getProcess, isGas } from "../../selectors/contract";
import { compose } from "redux";
import isNotEmpty from "../../validations/isNotEmpty";

const mapStateToProps = state => ({
  installationFloors: getEnumeration<InstallationFloor>(state, "installation_floors"),
  partnerValue: getPartnerValue(state),
  gas: isGas(state),
  lifecycle: getLifecycle(state),
  process: getProcess(state),
});

export interface DataFilledByCustomerProps {
  isCrossSell: boolean;
  disabled?: boolean;
  input: any;
  knownConsumption?: boolean;
  appliances: Appliance[];
  suppliers: Supplier[];
}

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

function DataFilledByCustomer(props: DataFilledByCustomerProps & PropsFromRedux) {
  const {
    input: { value, name },
    knownConsumption,
    suppliers,
    appliances,
    disabled,
    isCrossSell,
    installationFloors,
    lifecycle,
    process,
    partnerValue,
    gas,
  } = props;

  const { supplyPointAddressIsSame, supplyPointIsAFlat } = value || {};

  const disableCode = disabled || isCrossSell || lifecycle.transfer;

  const codeTooltip = disableCode ? undefined : <div className={styles.tooltip}>{locs("texts.eanOnYourContract")}</div>;
  const months = generateMonths();
  months.push({ label: locs("labels.unknown"), value: UNKNOWN });

  const eicProps = {
    label: locs("labels.eicCode"),
    placeholder: locs("placeholders.eic"),
    prefix: EIC_PREFIX,
    validate: [formValidation(isNotEmpty), formValidation(isEIC)],
    normalize: eic => (eic && eic.indexOf(EIC_PREFIX) === -1 ? `${EIC_PREFIX}${eic}` : eic).toUpperCase(),
    format: eicFormatter,
  };

  const eanProps = {
    label: locs("labels.eanCode"),
    placeholder: locs("placeholders.ean"),
    prefix: EAN_PREFIX,
    validate: [formValidation(isNotEmpty), formValidation(isEAN)],
    normalize: ean => (ean && ean.indexOf(EAN_PREFIX) === -1 ? `${EAN_PREFIX}${ean}` : ean).toUpperCase(),
    format: eanFormatter,
  };

  return (
    <div>
      <Title name={locs("titles.supplyPointData")} tag="h3" className={styles.title} />
      <Column columns={2} weights={["1_11", 1]}>
        <TooltipContainer overlay={codeTooltip} placement="right" align={{ offset: [20, 0] }} visible>
          <Field
            name={`${name}.code`}
            type="text"
            component={Input}
            disabled={disableCode}
            theme={theme}
            {...(!gas ? eanProps : eicProps)}
          />
        </TooltipContainer>
      </Column>
      {!knownConsumption && !gas && (
        <Column columns={2} weights={["1_11", 1]}>
          <Field
            name={`${name}.consumption`}
            props={{
              theme,
              doNotGroup: true,
            }}
            component={ElectricityConsumption}
          />
        </Column>
      )}
      {!knownConsumption && gas && (
        <Column columns={2} weights={["1_11", 1]}>
          <Field
            name={`${name}.consumption`}
            props={{
              theme,
            }}
            component={GasConsumption}
          />
        </Column>
      )}
      {!lifecycle.transfer && (
        <div className={styles.addressCheckbox}>
          <Field
            name={`${name}.supplyPointAddressIsSame`}
            type="checkbox"
            component={Checkbox}
            props={{
              disabled,
            }}
            label={locs("labels.supplyPointAddressIsSame")}
          />
        </div>
      )}
      {!supplyPointAddressIsSame && (
        <Field
          name={`${name}.supplyPointAddress`}
          component={Address}
          props={{
            className: styles.address,
            disabled: lifecycle.transfer || disabled,
            title: locs("titles.supplyPointAddress"),
          }}
        />
      )}
      {!lifecycle.transfer && (
        <>
          <div className={styles.addressCheckbox}>
            <Field
              name={`${name}.supplyPointIsAFlat`}
              type="checkbox"
              component={Checkbox}
              props={{
                disabled,
              }}
              label={locs("labels.supplyPointIsAFlat")}
            />
          </div>
          {supplyPointIsAFlat && (
            <Column columns={3} weights={["1_11", 1, 1]}>
              <Field
                name={`${name}.flatNumber`}
                component={Input}
                label={locs("labels.flatNumber")}
                validate={[formValidation(isNotEmpty), formValidation(isLength({ max: 10 }))]}
                props={{
                  disabled,
                  theme: {
                    ...theme,
                    inputContainer: theme.inputContainerFlat,
                  },
                }}
              />
              <Field
                name={`${name}.installationFloorId`}
                type="select"
                component={Select}
                props={{
                  disabled,
                  label: locs("labels.floor"),
                  theme: {
                    inputContainer: theme.inputContainerFlat,
                    label: theme.labelFloor,
                  },
                  data: formatEnumerationData<InstallationFloor>(installationFloors, "name"),
                }}
              />
            </Column>
          )}
        </>
      )}

      {lifecycle.selectCurrentSupplier && (
        <Field
          name={`${name}.currentSupplier`}
          props={{
            suppliers,
            disabled,
            lifecycle,
          }}
          component={CurrentSupplier}
        />
      )}
      {gas && (
        <Field
          name={`${name}.gasUsage`}
          props={{ appliances, disabled, partnerValue, consumptionRequired: process !== TYPE_NEW_OM }}
          component={GasUsage}
        />
      )}
    </div>
  );
}

export default compose(connector)(DataFilledByCustomer);
