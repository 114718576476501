import React, { PureComponent } from "react";
import { formValueSelector, InjectedFormProps, reduxForm } from "redux-form";
import { connect, ConnectedProps } from "react-redux";
import locs from "../../../localization";

import Container from "../../../containers/Container";
import Column from "../../../containers/Column";
import Title from "../../../components/Title";
import Button from "../../../components/Button";
import Link from "../../../components/Link";

import ProgressBar from "../../../components/ProgressBar";
import styles from "../styles.scss";
import recapStyles from "../../Recapitulation/styles.scss";

import { fetchEnumeration } from "../../../actions/enumerations";
import { getEnumerationRecordByValue } from "../../../reducers/enumerations";

import {
  ENUMERATION_COMMODITY,
  ENUMERATION_TITLE_PREFIX,
  ENUMERATION_TITLE_SUFFIX,
  ENUMERATION_PARTNER,
} from "../../../constants/enumerations";
import { compose } from "redux";
import ContactInfo from "../../Recapitulation/ContactInfo";
import { getTransfer, isHousehold, isProlongation, isTransfer } from "../../../selectors/contract";
import PaymentsInfo from "../../Recapitulation/PaymentsInfo";
import SupplyPointInfo from "../../Recapitulation/SupplyPointInfo";
import { Commodity, Partner } from "../../../constants/interfaces";
import { Pages } from "../../../lifecycles";
import BasicInfo from "../../Recapitulation/BasicInfo";
import InlineLoader from "../../../components/Loader/InlineLoader";

const requiredEnumerations = [ENUMERATION_COMMODITY, ENUMERATION_TITLE_PREFIX, ENUMERATION_TITLE_SUFFIX, ENUMERATION_PARTNER];
const formKey = "calculator";

// Decorate with connect to read form values
const selector = formValueSelector(formKey);

function mapStateToProps(state) {
  const enumerations = state.enumerations || {};
  const commodityValue = selector(state, "commodityValue");
  const partnerValue = selector(state, "partnerValue");
  const invoicesCount = (selector(state, "invoice") || []).length;

  return {
    /** get form field values */
    partner: getEnumerationRecordByValue<Partner>(enumerations, ENUMERATION_PARTNER, partnerValue),
    commodity: getEnumerationRecordByValue(enumerations, ENUMERATION_COMMODITY, commodityValue),
    household: isHousehold(state),
    contractData: {
      payments: selector(state, "payments"),
      contact: selector(state, "contact"),
      supplyPoint: selector(state, "supplyPoint"),
      product: selector(state, "product"),
      calculate: selector(state, `calculate.${commodityValue}`),
      invoicesCount,
      transfer: getTransfer(state),
      isProlongation: isProlongation(state),
    },
    isTransfer: isTransfer(state),

    /** get redux values */
    enumerations,
  };
}

const mapDispatchToProps = {
  fetchEnumeration,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface TransferRecapitulationProps {
  onSubmit: () => void;
  previousPage: () => void;
  toTheSpecificPage: (key: string) => void;
  commodity: Commodity;
}

class TransferRecapitulation extends PureComponent<PropsFromRedux & TransferRecapitulationProps & InjectedFormProps> {
  static defaultProps = {
    commodity: {},
    partner: {},
  };

  componentDidMount() {
    requiredEnumerations.forEach(this.props.fetchEnumeration);
  }

  render() {
    const { onSubmit, contractData, partner, toTheSpecificPage, previousPage, enumerations, commodity, submitting, isTransfer } =
      this.props;

    return (
      <Container>
        <div>
          <ProgressBar page={Pages.Recapitulation} />
          <Column>
            <Title name={locs("titles.checkData")} underline className={styles.title} />
          </Column>
          <div className={recapStyles.recapitulation}>
            <BasicInfo commodity={commodity} data={contractData.product} />
            <ContactInfo
              toTheSpecificPage={() => toTheSpecificPage("/contact")}
              data={contractData.contact}
              partner={partner}
              enumerations={enumerations}
              transfer={true}
            />

            <PaymentsInfo
              data={contractData.payments}
              enumerations={enumerations}
              toTheSpecificPage={() => toTheSpecificPage("/payments")}
              transfer={true}
            />

            <SupplyPointInfo
              data={contractData}
              enumerations={enumerations}
              toSupplyPoint={!isTransfer ? () => toTheSpecificPage("/supply_point") : undefined}
              toPreviousCustomer={!isTransfer ? () => toTheSpecificPage("/previous_customer") : undefined}
            />
          </div>
          <div className={styles.footerWithBackButton}>
            <Link label={locs("actions.goBackToForm")} onClick={previousPage} />
            <Button disabled={submitting} onClick={onSubmit}>
              {submitting ? <InlineLoader height={12} /> : locs("actions.submit_and_continue")}
            </Button>
          </div>
        </div>
      </Container>
    );
  }
}

export default compose(
  reduxForm({
    form: formKey, // <------ same form name
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  }),
  connector
)(TransferRecapitulation);
