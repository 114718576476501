import React, { PureComponent } from "react";
import { Field, formValueSelector, InjectedFormProps, reduxForm } from "redux-form";

import { connect, ConnectedProps } from "react-redux";
import locs from "../../localization";

import Container from "../../containers/Container";
import Column from "../../containers/Column";
import Title from "../../components/Title";
import Button from "../../components/Button";
import Link from "../../components/Link";
import ProgressBar from "../../components/ProgressBar";
import SupplyPoint from "../SupplyPoint";
import styles from "./styles.scss";

import { fetchEnumeration } from "../../actions/enumerations";
import {
  ENUMERATION_COMMODITY,
  ENUMERATION_SUPPLIER,
  ENUMERATION_APPLIANCE,
  ENUMERATION_CIRCUIT_BREAKER,
} from "../../constants/enumerations";
import { isCrossSell, isElectricity, isGas } from "../../selectors/contract";
import { compose } from "redux";
import { Appliance, CircuitBreaker, Commodity, Supplier } from "../../constants/interfaces";
import { getEnumeration, getEnumerationRecordByValue, getTariffs } from "../../selectors/enumerations";
import { Pages } from "../../lifecycles";
import { gasConsumptionChange } from "../Calculator/Consumption/Gas/Consumption";
import { prefillAppliances } from "../SupplyPoint/GasAppliance";
import { updateTariffAndCircuitBreakers } from "../Calculator/Consumption/Electricity/Consumption";
import { CalculatorValues } from "../../reducers/types";
import InlineLoader from "../../components/Loader/InlineLoader";

const requiredEnumerations = [ENUMERATION_COMMODITY, ENUMERATION_SUPPLIER, ENUMERATION_APPLIANCE];
const formKey = "calculator";

// Decorate with connect to read form values
const selector = formValueSelector(formKey);

function mapStateToProps(state) {
  const commodityValue = selector(state, "commodityValue");

  return {
    /** get form field values */
    commodity: getEnumerationRecordByValue<Commodity>(state, ENUMERATION_COMMODITY, commodityValue),
    knownConsumption: selector(state, `calculate.${commodityValue}.knownConsumption`),
    isElectricity: isElectricity(state),
    isCrossSell: isCrossSell(state) && isElectricity(state),
    isGas: isGas(state),
    tariffs: getTariffs(state),
    circuitBreakers: getEnumeration<CircuitBreaker>(state, ENUMERATION_CIRCUIT_BREAKER),
    appliances: getEnumeration<Appliance>(state, ENUMERATION_APPLIANCE),
    suppliers: getEnumeration<Supplier>(state, ENUMERATION_SUPPLIER),
  };
}

const mapDispatchToProps = {
  fetchEnumeration,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface SupplyPointFormProps {
  previousPage: () => void;
}

export class SupplyPointForm extends PureComponent<SupplyPointFormProps & PropsFromRedux & InjectedFormProps> {
  componentDidMount() {
    requiredEnumerations.forEach(this.props.fetchEnumeration);
  }

  render() {
    const {
      handleSubmit,
      previousPage,
      commodity,
      knownConsumption,
      isElectricity,
      isCrossSell,
      appliances,
      suppliers,
      submitting,
    } = this.props;

    // Sometimes commodity get later... don't know why
    if (!commodity) {
      return "";
    }

    const filteredSuppliers = suppliers.filter(t => !t.hidden && t.commodityId === commodity.id);

    let commodityLabel = isElectricity
      ? locs("titles.electricitySupplyPointInformation")
      : locs("titles.gasSupplyPointInformation");

    return (
      <Container>
        <div>
          <ProgressBar page={Pages.SupplyPoint} />
          <Column>
            <Title name={commodityLabel} underline />
          </Column>
          <Field
            name="supplyPoint"
            type="text"
            component={SupplyPoint}
            props={{
              suppliers: filteredSuppliers,
              appliances,
              knownConsumption,
              isCrossSell,
              commodity,
            }}
          />
          <div className={styles.footerWithBackButton}>
            <Link label={locs("actions.go_back")} onClick={previousPage} />
            <Button disabled={submitting} onClick={handleSubmit}>
              {submitting ? <InlineLoader height={12} /> : locs("actions.next")}
            </Button>
          </div>
        </div>
      </Container>
    );
  }
}

export default compose(
  connector,
  reduxForm({
    form: formKey, // <------ same form name
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    onChange: (
      values: CalculatorValues,
      dispatch,
      props: InjectedFormProps & PropsFromRedux,
      previousValue: CalculatorValues
    ) => {
      if (props.isGas) {
        // Gas callbacks
        gasConsumptionChange({
          values: values.supplyPoint?.data?.consumption,
          previousValues: previousValue.supplyPoint?.data?.consumption,
          dispatch,
          props,
          path: "supplyPoint.data.consumption",
        });
      }

      // Prefill appliances
      prefillAppliances(values, dispatch, props, props.appliances, previousValue);

      // Update tariffs and circuit breakers
      if (!props.isGas) {
        updateTariffAndCircuitBreakers({
          values: values.supplyPoint?.data?.consumption,
          previousValues: previousValue.supplyPoint?.data?.consumption,
          dispatch,
          props,
          tariffs: props.tariffs,
          circuitBreakers: props.circuitBreakers,
          path: "supplyPoint.data.consumption",
        });
      }
    },
  })
)(SupplyPointForm);
