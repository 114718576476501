import React from "react";
import moment from "moment";
import locs from "../../localization";
import Title from "../../components/Title";
import Column from "../../containers/Column";
import Input from "../../components/Input";
import Select from "../../components/Select";
import Group from "../../components/Group";
import Date from "../../components/Date";
import dateFormatter from "../../formatters/date";
import format from "string-template";

import { formatEnumerationData } from "../../helpers";
import formValidation from "../../validations/formValidation";
import isDate from "../../validations/isDate";
import isNumber from "../../validations/isNumber";
import isLength from "../../validations/isLength";

import {
  CONTRACT_VALIDITY_INDEFINITE,
  CONTRACT_VALIDITY_CERTAIN_TIME,
  CONTRACT_VALIDITY_TERMINATED,
  DATE_FORMAT,
} from "../../constants";

import styles from "./styles.scss";
import theme from "./theme.scss";
import { Supplier } from "../../constants/interfaces";
import { Field, WrappedFieldProps } from "redux-form";
import Checkbox from "../../components/Checkbox";
import { Lifecycle } from "../../lifecycles";
import isNotEmpty from "../../validations/isNotEmpty";
import FixedTooltip from "../../components/FixedTooltip";
import inFuture from "../../validations/inFuture";
import { getPreviousContractValidTo } from "../../selectors/contract";
import { compose } from "redux";
import { ConnectedProps, connect } from "react-redux";

export interface CurrentSupplierProps {
  suppliers: Supplier[];
  disabled?: boolean;
  lifecycle: Lifecycle;
  showPreviousContractTermination?: boolean;
}

interface ValidToProps {
  name: string;
  disabled: boolean;
  days?: number;
  tooltip?: string;
}

const validTo = ({ name, disabled, days, tooltip }: ValidToProps) => {
  const addDays = days || 0;
  const endOfMonth = moment().add(addDays, "days").endOf("month").format(DATE_FORMAT);
  const validToPlaceholder = format(locs("placeholders.contractValidTo"), { date: endOfMonth });

  let validDay = undefined;
  let errorMessage = undefined;
  if (days) {
    validDay = (currentDate, _selectedDate) => currentDate.isAfter(moment().add(addDays, "days"));
    errorMessage = format(locs("errors.validToInFuture"), { days });
  }

  return (
    <>
      {tooltip && (
        <FixedTooltip position="none" theme={{ tooltipContainer: styles.validToTooltip }}>
          <div dangerouslySetInnerHTML={{ __html: tooltip }} />
        </FixedTooltip>
      )}
      <Column columns={1} weights={[1]}>
        <Field
          name={`${name}.validTo`}
          type="text"
          component={Date}
          props={{
            disabled,
            isValidDate: validDay,
            placeholder: validToPlaceholder,
            theme,
          }}
          label={locs("labels.contractValidTo")}
          validate={[formValidation(isNotEmpty), formValidation(isDate), formValidation(inFuture(days), errorMessage)]}
          normalize={dateFormatter}
        />
      </Column>
    </>
  );
};

function mapStateToProps(state) {
  return {
    previousContractValidTo: getPreviousContractValidTo(state),
  };
}

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const noticePeriod = (name: string, disabled: boolean, terminationDate?: string) => {
  // if terminationDate is null, maxDays will be NaN and the validation will pass
  const maxDays = moment(terminationDate, "DD.MM.YYYY").diff(moment(), "days");

  return (
    <Column columns={2} weights={["1_11", 1]}>
      <Field
        name={`${name}.noticePeriod`}
        type="number"
        component={Input}
        props={{
          disabled,
          placeholder: locs("placeholders.noticePeriod"),
          suffix: locs("labels.days"),
          theme: {
            inputContainer: theme.inputContainerNoticePeriod,
            label: theme.label,
          },
        }}
        label={locs("labels.noticePeriod")}
        validate={[
          formValidation(isNumber({ gte: 0 })),
          formValidation(isNumber({ max: maxDays }), locs("errors.noticePeriodTooLong")),
          formValidation(isLength({ max: 2 })),
          formValidation(isNotEmpty),
        ]}
      />
    </Column>
  );
};

function CurrentSupplier(props: CurrentSupplierProps & WrappedFieldProps & PropsFromRedux) {
  const {
    input: { name, value: { contractValidity, previousContractTerminated } = {} },
    suppliers,
    disabled,
    lifecycle,
    previousContractValidTo,
  } = props;

  const certain = contractValidity === CONTRACT_VALIDITY_CERTAIN_TIME;
  const indefinite = contractValidity === CONTRACT_VALIDITY_INDEFINITE;
  const terminated = contractValidity === CONTRACT_VALIDITY_TERMINATED;

  return (
    <div className={styles.currentSupplier}>
      <Title name={locs("titles.currentSupplier")} tag="h3" className={styles.title} />

      <Column columns={2} weights={["1_11", 1]}>
        <Field
          name={`${name}.supplierId`}
          type="select"
          component={Select}
          validate={[formValidation(isNotEmpty)]}
          props={{
            disabled,
            theme,
            showBlankOption: true,
            data: formatEnumerationData(suppliers, "desc"),
          }}
          label={locs("labels.supplier")}
        />
      </Column>

      {lifecycle.showPreviousContractTermination && (
        <>
          <div className={styles.addressCheckbox}>
            <Field
              name={`${name}.previousContractTerminated`}
              type="checkbox"
              component={Checkbox}
              props={{
                disabled,
              }}
              label={locs("labels.previousContractTerminated")}
            />
          </div>
          {previousContractTerminated && validTo({ name, disabled, tooltip: locs("texts.contractTerminationChangeCustomer") })}
        </>
      )}

      {lifecycle.showContractValidity && (
        <>
          <Column>
            <Group
              label={locs("labels.contractValidity")}
              name={`${name}.contractValidity`}
              data={[
                {
                  label: locs("labels.contractValidityCertainTime"),
                  value: CONTRACT_VALIDITY_CERTAIN_TIME,
                  disabled,
                },
                {
                  label: locs("labels.contractValidityIndefinite"),
                  value: CONTRACT_VALIDITY_INDEFINITE,
                  disabled,
                },
                {
                  label: locs("labels.contractValidityTerminated"),
                  value: CONTRACT_VALIDITY_TERMINATED,
                  disabled,
                },
              ]}
            />
          </Column>
          {certain && (
            <>
              {validTo({ name, disabled, days: 30 })} {noticePeriod(name, disabled, previousContractValidTo)}
            </>
          )}
          {indefinite && noticePeriod(name, disabled)}
          {terminated && validTo({ name, disabled, days: 20, tooltip: locs("texts.contractTermination") })}
        </>
      )}
    </div>
  );
}
export default compose(connector)(CurrentSupplier);
