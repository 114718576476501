import React, { PureComponent } from "react";
import { Field, formValueSelector, InjectedFormProps, reduxForm, WrappedFieldProps } from "redux-form";
import { connect, ConnectedProps } from "react-redux";
import locs from "../../localization";

import Container from "../../containers/Container";
import Column from "../../containers/Column";
import Title from "../../components/Title";
import Button from "../../components/Button";
import Link from "../../components/Link";

import ProgressBar from "../../components/ProgressBar";
import Recapitulation from "../Recapitulation";
import styles from "./styles.scss";

import { fetchEnumeration } from "../../actions/enumerations";
import { getEnumerationRecordByValue } from "../../reducers/enumerations";
import {
  ENUMERATION_COMMODITY,
  ENUMERATION_TITLE_PREFIX,
  ENUMERATION_TITLE_SUFFIX,
  ENUMERATION_PARTNER,
} from "../../constants/enumerations";
import { compose } from "redux";
import { getLifecycle, isProlongation } from "../../selectors/contract";
import { Pages } from "../../lifecycles";
import InlineLoader from "../../components/Loader/InlineLoader";

const requiredEnumerations = [ENUMERATION_COMMODITY, ENUMERATION_TITLE_PREFIX, ENUMERATION_TITLE_SUFFIX, ENUMERATION_PARTNER];
const formKey = "calculator";

// Decorate with connect to read form values
const selector = formValueSelector(formKey);

function mapStateToProps(state) {
  const enumerations = state.enumerations || {};
  const commodityValue = selector(state, "commodityValue");
  const partnerValue = selector(state, "partnerValue");
  const invoicesCount = (selector(state, "invoice") || []).length;

  return {
    /** get form field values */
    commodity: getEnumerationRecordByValue(enumerations, ENUMERATION_COMMODITY, commodityValue),
    partner: getEnumerationRecordByValue(enumerations, ENUMERATION_PARTNER, partnerValue),
    contractData: {
      payments: selector(state, "payments"),
      contact: selector(state, "contact"),
      supplyPoint: selector(state, "supplyPoint"),
      product: selector(state, "product"),
      calculate: selector(state, `calculate.${commodityValue}`),
      isProlongation: isProlongation(state),
      invoicesCount,
    },
    lifecycle: getLifecycle(state),

    /** get redux values */
    enumerations,
  };
}

const mapDispatchToProps = {
  fetchEnumeration,
};

interface RecapitulationFormProps {
  previousPage: () => void;
  toTheSpecificPage: (key: string) => void;
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export class RecapitulationForm extends PureComponent<RecapitulationFormProps & PropsFromRedux & InjectedFormProps> {
  componentDidMount() {
    requiredEnumerations.forEach(this.props.fetchEnumeration);
  }

  render() {
    const {
      handleSubmit,
      commodity,
      contractData,
      partner,
      toTheSpecificPage,
      previousPage,
      enumerations,
      lifecycle,
      submitting,
    } = this.props;

    return (
      <Container>
        <div>
          <ProgressBar page={Pages.Recapitulation} />
          <Column>
            <Title name={locs("titles.checkData")} underline className={styles.title} />
          </Column>
          <Field
            name="recapitulation"
            component={Recapitulation}
            props={{
              commodity,
              partner,
              enumerations,
              contractData,
              toTheSpecificPage,
              lifecycle,
            }}
          />
          <div className={styles.footerWithBackButton}>
            <Link label={locs("actions.goBackToForm")} onClick={previousPage} />
            <Button disabled={submitting} onClick={handleSubmit}>
              {submitting ? <InlineLoader height={12} /> : locs("actions.submit_and_continue")}
            </Button>
          </div>
        </div>
      </Container>
    );
  }
}

export default compose(
  reduxForm({
    form: formKey, // <------ same form name
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  }),
  connector
)(RecapitulationForm);
